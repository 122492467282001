button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0072c4;
  border: none;
  min-width: 100px;
  padding: 10px 15px;
  color: #fff;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  font-size: 1em;

  &:disabled {
    opacity: 0.5;
  }
}

.btn {
  padding: 0;
  min-width: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.options {
  width: auto;
}

.close-btn {
  margin-left: 5px;
  top: -10px;
  right: -10px;
  background-color: #fff;
  border: 1px solid rgb(158, 158, 158);

  &.close-bin{
    background: #000;
    border-color: #00000000;
  }

  &.gray-icon{
    max-height: 11px;
  }
  img{
    max-height: 14px;
  }
}

.delete-btn {
  background-color: #ff0000;

  .delete-btn-icon {
    max-height: 16px;
  }

  img {
    max-height: 11px;
  }
}

.submit-btn {
  position: absolute;
  z-index: 13;
  right: 3px;
  bottom: 3px;
  background: #00B01C;
  &.edit-date{
    position: static;
  }

  img {
    max-height: 11px;
  }
}

.next-btn,
.prev-btn {
  background-color: #fff;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.edit-btn {
  background-color: #000;

  img {
    max-height: 15px;
  }
}

.edit-btn-tools {
  margin-left: 8px;
}

.upload-btn {
  position: relative;

  .input {
    display: none;
    position: absolute;
    z-index: 1;
  }

  img {
    max-height: 100%;
    z-index: 2;
    cursor: pointer;
  }
}

.download-btn{
  background-color: #0072c4;
  opacity: 1;

  img {
    max-height: 13px;
    transform: rotate(180deg);
  }
}

.expand-btn{
  background-color: #000000;
  opacity: 1;

  img {
    max-height: 13px;
  }
}

.save-btn {
  background-color: #00B01C;

  img {
    max-height: 13px;
  }
}

.head-section {
  .title {
    .collapse-btn {
      background: #fff;
      max-height: 50px;
      padding: 5px 0 10px;
      border: 1px solid #C4C4C4;
      margin-right: 15px;
      transform: rotate(0deg);
      transition: 0.4s all;

      &.collapsed {
        transform: rotate(180deg);
      }
    }
  }
}
.collapse-btn {
  cursor: pointer;
  max-height: 15px;
  margin-right: 15px;
  transform: rotate(180deg);
  transition: 0.4s all;

  &.collapsed {
    transform: rotate(0deg);
  }
}

.file-bit-btn{
  img{
    height: 11px;
  }
}

.resize-btn{
  margin-left: 5px;
  img{
    height: 15px;
  }
}
