*{
  hyphens: manual;
  overflow-wrap: break-word;
}

.notification-container{
  .message{
    word-break: normal;
  }
}

.root{
  height: 100vh;
  overflow: hidden;
}

.App {
  position: relative;
  height: 100vh;
  position: fixed;
  margin: 0 auto;
  display: flex;
  left: 0;
  right: 0;
  background-color: #fff;
  overflow: hidden;
  @media all and (max-width: 930px) {
    max-width: 100%;
  }
}

.notification-container {
  bottom: 0;
  top: auto!important;

  .notification {
    box-shadow: 0 0 5px #999;
    transition:  0.4s all;
    &-hide{
      transform: translateX(110%);
    }
  }
}
