* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 px #c7e7ff;
    background: linear-gradient(to bottom, #000 0%, #ccc 100%);
    border-radius: 3px;
  }
}

html,
body,
button,
a {
  font-family: 'Open Sans', sans-serif;
}

body {
  font-size: 15px;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

@font-face {
  font-family: 'Notification';
  src: url('./fonts/notification.eot?s3g3t9');
  src: url('./fonts/notification.eot?#iefixs3g3t9') format('embedded-opentype'),
  url('./fonts/notification.woff?s3g3t9') format('woff'),
  url('./fonts/notification.ttf?s3g3t9') format('truetype'),
  url('./fonts/notification.svg?s3g3t9#notification') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import './editables/all-files.scss';
@import './editables/common.scss';
@import './editables/buttons.scss';
@import './editables/toolbar.scss';
@import './editables/file-bin.scss';
@import './editables/popups.scss';
@import './editables/notifications.scss';