@mixin text-field {
  display: block;
  border: none;
  outline: none;
  resize: none;
  font-family: 'Open Sans';
  font-size: 0.85em;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 px #c7e7ff;
    background: linear-gradient(to bottom, #000 0%, #ccc 100%);
    border-radius: 3px;
  }
}

.file-manager {
  position: relative;
  width: calc(100% - 600px);
  min-width: 300px;
  height: 100%;
  overflow: hidden;
  background-color: #ccc;

  &__toggler {
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    right: 25px;
    top: 25px;
    font-size: 1em;
    border-radius: 5px;

    &.hidden {
      display: none;
    }

    img {
      max-height: 15px;
      transform: rotate(45deg);
      margin-left: 5px;
    }
  }

  &__container {
    height: 100%;
    background-color: #f8f8f8;
    padding: 10px 25px 15px;

    &.single_section_embed {
      padding: 0;
      margin: 0;
      height: unset;
      background-color: transparent;
    }

    &.view_file_embed {
      padding: 0;
      margin: 0;
      height: 20px;
      background-color: transparent;
      cursor: pointer;
    }

    &.half {
      height: 50%;
    }

    .wrapper {
      overflow: auto;
      height: calc(100% - 40px);

      .other-deal-folder {
        padding-top: 1rem;

        .other-deals-date {
          font-weight: bold;
        }

        .files-section{
          .btn.download-btn{
            right: -13px;
          }
        }
      }
    }

    &.preview-open {
      .wrapper {
        padding-bottom: 60vh;
      }
    }

    .head-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;

      .return-btn {
        display: flex;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        background-color: #0072c4;
        border-radius: 5px;
        cursor: pointer;

        &.disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }

        img {
          max-height: 13px;
        }
      }

      .location {
        display: flex;
        align-items: center;
        font-weight: 600;

        .divider {
          cursor: pointer;
        }

        .breadcrumb {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          &:last-child {
            cursor: text;
            text-decoration: none;
          }
        }

        .edit-btn {
          margin-left: 7px;
        }

        .edit-name-form {
          position: relative;
          height: 30px;
          border: 1px solid #000;
          width: 150px;
          left: 10px;

          .text-field {
            height: 100%;
            padding: 6px 0 0;
            @include text-field;
          }

          .submit-btn {
            position: absolute;
            right: 1px;
            bottom: 1px;
          }
        }
      }

      .options {
        display: flex;
        align-items: center;

        .file-manager-sort-btn {
          margin-right: 3px;
          padding: 5px 18px;
          min-width: 0;
        }

        .upload-btn {
          margin-right: 5px;
        }

        .download-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          background-color: #0072c4;
          cursor: pointer;

          img {
            max-height: 13px;
            transform: rotate(180deg);
          }
        }

        .delete-toggler {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          margin-right: 3px;
          cursor: pointer;
          transition: 0.3s all;

          &.active {
            box-shadow: 0 0 10px rgb(87, 87, 87);
            border-radius: 50%;
            background-color: rgba(196, 0, 0, 0.45);
            width: 40px;
            height: 40px;
          }

          img {
            width: 23px;
            height: 25px;
          }
        }
      }
    }

    .folder-section {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      min-height: 86px;
      margin-top: 20px;
      margin-bottom: -20px;
      width: calc(100% - 20px);

      &.collapsed {
        min-height: 0;
      }

      .add-folder {
        width: 160px;
        padding: 15px;
        margin: 0 20px 20px 0;
        background-color: #E4E4E4;
        color: #000;
        font-weight: 600;
        min-height: 66px;

        &.fixed {
          position: absolute;
          top: 0;
          right: 30px;
        }

        img {
          max-height: 25px;
          margin-right: 8px;
        }
      }
    }

    .folder {
      position: relative;
      width: 160px;
      height: 120px;
      margin: 0 20px 20px 0;
      display: flex;
      align-items: center;
      padding: 15px 30px 15px 15px;
      background-color: #dde9ff;
      font-weight: 600;
      font-size: 0.9em;
      border-radius: 5px;
      word-break: break-all;

      &.other-deals {
        background-color: #e5e5e5;
      }

      .click-panel {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 1;
      }

      .folder-icon {
        max-height: 25px;
        margin-right: 12px;
      }

      .delete-btn,
      .edit-btn {
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 2;
      }

      .edit-btn {
        right: 17px;

        &.trash-edit{
          right: 5px;
        }
      }

      .edit-name-form {
        position: absolute;
        height: 50px;
        width: 80%;
        z-index: 3;

        .text-field {
          height: 50px;
          width: 100%;
          @include text-field
        }
      }
    }

    .files-section {
      padding-top: 20px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      &.folder-section-single-embed {
        margin: 0;
        padding: 0;
        .container {
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          min-height: 100px;

          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;

          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          .icon {
            cursor: pointer;
            img {
              width: 25px;
              margin: 0px 29px;
            }
          }
        }
      }

      .upload-zone {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .container {
          width: 200px;
          height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        span {
          text-align: center;
        }

        .icon {
          background-color: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          height: 70px;
          margin-bottom: 10px;
          border-radius: 50%;
          cursor: pointer;

          img {
            max-height: 60px;
          }
        }

        input {
          display: none;
        }
      }

      .file {
        position: relative;
        display: inline-block;
        position: relative;
        margin-right: 20px;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 0.9em;
        cursor: pointer;
        outline: none;

        &.file-embed {
          margin-bottom: 6px;
          margin-right: 6px;
        }

        .document {
          width: 160px;
          height: 120px;
          overflow: hidden;
          position: relative;
          border-radius: 4px;
          border: 1px solid #ccc;
          background-color: #fff;
          pointer-events: none;
        }

        .previewed-document {
          border-color: #ececec;
        }

        .previewed-document-img {
          width: 160px;
          overflow: hidden;
          height: 120px;
          border-radius: 4px;
          border-radius: 4px;
          border: 1px solid #ccc;
          background-color: #fff;
          img {
            width: 100%;
          }
        }

        .pdf-loading {
          border-radius: 50%;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          border-top: 1px solid #414042;
          border-right: 1px solid #414042;
          border-bottom: 1px solid #414042;
          border-left: 1px solid transparent;
          transform: translate(-50%, -50%) rotate(0deg);
          animation: rotate 1.3s infinite cubic-bezier(0.4, 0.85, 0.65, 0.25);
        }

        .title {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #f0f0f0;
          color: #414042;
          width: 100%;
          padding: 6px 12px;
          overflow: hidden;
        }

        .protected-file {
          text-align: center;
        }

        .protected-file-message {
          display: block;
          margin-top: 35px;
        }

        .delete-btn {
          position: absolute;
          top: -10px;
          right: -10px;
        }

        .download-btn {
          position: absolute;
          top: -10px;
          right: 17px;

          &.trash-download {
            right: 5px;
          }

          &.thread-doc-download {
            right: -13px;
          }
        }
      }
    }
  }
  @import './file-preview.scss';
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}