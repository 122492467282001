.filesContainer {
  display: flex;
  flex-direction: column;

  .filesCategoryName {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #787878;
    margin-bottom: 8px;
  }

  .fileWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 13px;
  }
}

.allFilesPage {
  padding: 30px;
}

.uploadContainer {
  background: #f8f8f8;
  min-height: 120px;
  max-height: 120px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  .uploadImg {
    width: 25px;
    height: 25px;
  }
}

.single-file-upload {
  height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .upload-section {
    z-index: 2;

    &__input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  @import './date-picker';
}

.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none;
}

.loader-content {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-top: 5px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.file-loader {
  &-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }

  &-container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 20px 0;
  }
}

