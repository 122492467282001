.date-picker {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__month {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
        width: 23%;
        height: 47px;
        background: #F1F1F1;
        border: 1px solid #E5E5E5;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background: #C7C7C7;

            i {
                display: block;
            }
        }

        i {
            position: absolute;
            display: none;
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            cursor: pointer;

            &.right {
                right: 12px;
                border-left: 10px solid #747474;

                &:hover {
                    border-left: 10px solid #0072c4;
                }

                &.disabled {
                    border-left: 10px solid #E5E5E5;
                    cursor: not-allowed;
                }
            }

            &.left {
                left: 12px;
                border-right: 10px solid #747474;

                &:hover {
                    border-right: 10px solid #0072c4;
                }

                &.disabled {
                    border-right: 10px solid #E5E5E5;
                    cursor: not-allowed;
                }
            }
        }
    }
}
