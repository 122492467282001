@mixin pdfHideScroll {
  .PDFObject-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    background: #323639;
    #pdfobject {
      margin-top: 15px;
      height: 100%;
      width: calc(100% + 22px);
    }
  }
}

&__preview {
  background-color: #949494;
  position: relative;
  overflow: hidden;

  @import './docx.scss';

  .wrapper {
    overflow-y: scroll;
    height: 96%;

    canvas {
      width: 100%;
    }

    iframe {
      min-height: 320px;
      height: 100%;
    }

    img {
      height: auto !important;
      width: 100%;
    }

    .document-container {
      width: 100%;
    }

    @include pdfHideScroll;
  }

  .head-section {
    position: absolute;
    right: 35px;
    bottom: 10px;
    .full-preview-btn {
      cursor: pointer;
    }
  }

  &__toggle-area {
    position: absolute;
    right: 0;
    padding: 70px 0 100px;
    width: 80px;
    z-index: 2;

    & > .head-section {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 65px;
      right: 0%;
      position: absolute;
      width: 110px;
      height: 39px;
      background: #b6b6b6;
      border-radius: 5px 0px 0px 5px;
      transition: all 0.5s;
    }
  }

  &-toggle-bin {
    position: absolute;
    right: 0%;
    min-width: 0;
    padding: 10px;
    border-radius: 5px 0 0 5px;
    transition: all 0.5s;
    & > img {
      width: 22px;
      margin-left: 2px;
    }
  }

  &:not(.file-manager__fullpreview) {
    position: relative;
    height: 100%;
    z-index: 5;
    #preview-wrapper,
    #pdfobject {
      height: 100%;
    }
  }
}

&__container + &__preview {
  height: 50%;
  bottom: 50%;

  &.full-height {
    height: 100%;
    bottom: 100%;
  }

  .adjust-area {
    height: 3px;
    cursor: n-resize;
  }

  .wrapper {
    height: 100%;
  }

  & > .head-section {
    right: 60px;
    bottom: 0px;
  }

  .full-preview-btn {
    position: absolute;
    bottom: 15px;
  }

  .pg-viewer {
    width: 100%;
  }
}

&__fullpreview {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 15px 25px;
  background: #545454;

  img {
    margin: auto;
    display: block;
  }

  .head-section {
    position: relative;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 0;
    margin-bottom: 10px;
    padding: 0 20px;

    & > .preview-file-title {
      width: calc(100% - 53px);
      color: #fff;
    }

    &:last-child {
      position: absolute;
      right: 10px;
      bottom: 6px;
    }

    .half &:last-child {
      position: absolute;
      top: 310px;
      right: 15px;
    }
  }

  .wrapper {
    overflow: auto;
    height: calc(100% - 40px);
    max-height: 92%;
    padding: 0;
  }

  .label {
    box-sizing: border-box;
    border-radius: 4px;
    color: #fff;
    padding: 5px 10px;
    font-weight: normal;
    margin-right: 10px;
  }

  .head-section {
    align-items: center;
    justify-content: space-between;

    .first-buttons-section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .preview-file-title {
        margin-left: 0.5rem;
        color: #fff;
      }

      .close-btn {
        margin-left: 0;
      }

      .select-menu {
        top: 45px;
        height: 75vh;
        background: #ffffff;
        border: 1px solid #979797;
        box-sizing: border-box;
        border-radius: 4px;
        width: 312px;
        overflow-y: scroll;
        position: absolute;
        z-index: 9;

        .add-folder {
          padding: 5px 15px;

          img {
            max-height: 20px;
          }
        }

        .row {
          display: flex;
          font-weight: 600;
          font-size: 14px;
          align-items: center;
          padding: 7px 13px;
          cursor: pointer;

          &.add-bank {
            padding: 4px 13px;

            & > .add-folder {
              padding: 5px 15px 5px 7px;

              img {
                margin-right: 6px;
              }
            }
          }

          &:nth-child(1) {
            padding: 13px 7px 7px 13px;
          }

          &:last-child {
            padding: 7px 7px 13px 13px;
          }

          .banks-label {
            background: #0072c4;
          }

          .subdocs-label {
            background: #00b01c;
          }

          .contracts-label {
            background: #6100ff;
          }

          .stips-label {
            background: #ffb800;
          }

          &:hover {
            background: #e5e5e5;
          }
        }
      }

      .select {
        margin-left: 14px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('../../Images/select-down-btn.png');
        background-repeat: no-repeat;
        background-position-x: 90%;
        background-position-y: 55%;
        font-weight: 600;
        font-size: 14px;
        font-family: inherit;
        border: 1px solid #979797;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        height: 44px;
        min-width: 85px;
      }

      .select-year-month {
        min-width: 200px;
      }

      .select-bank-account {
        min-width: 312px;
      }

      .btn {
        border-radius: 4px;
        color: #fff;
        height: 44px;
        text-transform: uppercase;
        margin-left: 14px;
      }

      .save-btn {
        background: #0072c4;
        min-width: 54px;
      }

      .cancel-btn {
        background: #ff0000;
        min-width: 73px;
      }

      .file-location {
        background: #ffffff;
        border-radius: 4px;
        width: 312px;
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px;
        cursor: pointer;
        overflow: hidden;

        span {
          font-weight: 600;
          font-size: 14px;
        }

        .row {
          display: flex;
          font-weight: 600;
          font-size: 14px;
          align-items: center;
          cursor: pointer;

          .banks-label {
            background: #0072c4;
          }

          .subdocs-label {
            background: #00b01c;
          }

          .contracts-label {
            background: #6100ff;
          }

          .stips-label {
            background: #ffb800;
          }
        }
      }

      .date-select-section {
        display: flex;
      }

      .select-btn .close {
        transform: rotate(180deg);
      }
    }

    .second-buttons-section {
      display: flex;
      width: 150px;
      justify-content: space-between;
    }
  }
}
