.toolbar {
  width: 600px;

  .nav {
    padding: 15px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;

    &-buttons__container {
      display: flex;
      justify-content: space-between;
      width: 90%;
    }

    .download-btn,
    .expand-btn {
      margin-top: 5px;
    }

    .nav-btn {
      min-width: 18%;
      padding: 7px 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      background-color: transparent;
      cursor: pointer;
      outline: none;
      font-size: 1em;
      color: #494949;
      transition: all 0.15s;
      margin-right: 0.3rem;

      &.active {
        color: #fff;

        &.banks {
          background-color: #0072c4;
          border-color: #0072c4;
        }

        &.sub-docs {
          background-color: #00b01c;
          border-color: #00b01c;
        }

        &.contracts {
          background-color: #6100ff;
          border-color: #6100ff;
        }

        &.stips {
          background-color: #ffb800;
          border-color: #ffb800;
        }

        &.view-all {
          background-color: #000;
          border-color: #000;
        }
      }

      &.other {
        visibility: hidden;
        @media all and (max-width: 1100px) {
          display: none;
        }
      }
    }
  }

  &__container {
    padding: 20px;
    height: calc(100% - 65px);
    overflow: auto;

    &.all {
      padding: 0 10px;
      overflow-y: scroll;
      .toolbar__container {
        padding: 20px 20px;
        overflow: visible;
        height: fit-content;
        &.container-collapsed {
          padding: 20px 20px 10px;
        }
      }
    }

    &-divider {
      border-bottom: 1px solid #c4c4c4;
      width: calc(100% - 20px);
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 px #c7e7ff;
      background: linear-gradient(to bottom, #000 0%, #ccc 100%);
      border-radius: 3px;
    }

    &.subdocs {
      .add-folder {
        background-color: #00b01c;
      }
    }

    &.contracts {
      .add-folder {
        background-color: #6100ff;
      }
    }

    &.stips {
      .add-folder {
        background-color: #ffb800;
      }
    }

    &.other {
      .add-folder {
        background-color: #8d00b0;
      }
    }

    .head-section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.2em;
        min-height: 40px;
        font-weight: 600;

        .icon {
          max-height: 25px;
          margin-right: 10px;
        }

        .download-btn {
          margin-left: 10px;
        }
      }

      .add-folder {
        .icon {
          max-height: 18px;
          margin-right: 5px;
        }
      }
    }

    .subsection {
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 20px;
      transition: 0.3s all ease;
      max-height: 95%;
      border-radius: 5px;
      overflow: auto;
      margin: 10px 0;

      &.bordered {
        border: 1px solid #ccc;
        padding: 20px;
        padding-bottom: 10px;
      }

      &.filled:hover,
      &.dragenter {
        box-shadow: 0 0 0 1.5px #b6b6b6;
        border-color: #b6b6b6;
      }

      &.collapsed {
        overflow: hidden;
        max-height: 67px;

        .collapse-btn {
          transform: rotate(180deg);
        }
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: #fff;
      }

      &::-webkit-scrollbar {
        width: 4px;
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 px #c7e7ff;
        background: linear-gradient(to bottom, #000 0%, #ccc 100%);
        border-radius: 3px;
      }

      & > .delete-section-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 3;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .upload-section {
        display: flex;
        align-items: center;

        z-index: 2;

        &__input {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }

        &.Banks .upload-section__icon {
          background-color: #0072c4;
        }

        &.SubDocs .upload-section__icon {
          background-color: #00b01c;
        }

        &.Contracts .upload-section__icon {
          background-color: #6100ff;
        }

        &.Stips .upload-section__icon {
          background-color: #ffb800;
        }

        &__icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 15px;

          img {
            max-height: 30px;
          }
        }
      }

      &__head-section {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        z-index: 3;

        .increase-year-btn {
          width: 0;
          height: 0;
          margin-left: 5px;
          margin-right: 5px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 10px solid #747474;
          cursor: pointer;

          &:hover {
            border-bottom: 10px solid #0072c4;
          }
        }

        .date-picker-toggler {
          width: 25px;
          height: 25px;
          margin-left: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #0072c4;
          cursor: pointer;

          &.active {
            background-color: #747474;
          }

          img {
            max-height: 13px;
          }
        }

        .decrease-year-btn {
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 10px solid #747474;
          cursor: pointer;

          &:hover {
            border-top: 10px solid #0072c4;
          }
        }

        .title {
          display: flex;
          align-items: center;
          font-weight: 600;

          .upload-btn {
            margin-left: 5px;
          }

          .download-btn {
            margin-left: 5px;
          }
        }

        .sub-title {
          display: flex;
          align-items: center;
        }

        .section_actions {
          justify-content: space-between;
        }

        .collapse-btn {
          max-height: 13px;
          cursor: pointer;
          transform: rotate(0deg);
          transition: 0.5s all;
        }
      }

      .file-section {
        display: flex;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #ccc;
        margin-bottom: 10px;
        background-color: #fff;
        &.previewed-file-section {
          background-color: #ececec;
          border-color: #ececec;
        }

        &.uploading {
          opacity: 0.5;
          pointer-events: none;
        }

        .edit-description-form {
          width: 100%;

          .text-field {
            display: block;
            border: none;
            outline: none;
            width: 100%;
            resize: none;
            font-family: 'Open Sans';
            font-size: 0.85em;

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
              background-color: #fff;
            }

            &::-webkit-scrollbar {
              width: 4px;
              background-color: #fff;
            }

            &::-webkit-scrollbar-thumb {
              -webkit-box-shadow: inset 0 0 px #c7e7ff;
              background: linear-gradient(to bottom, #000 0%, #ccc 100%);
              border-radius: 3px;
            }
          }
        }

        .type {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 50px;
          width: 50px;
          margin-right: 15px;
          background-color: #494949;
          color: #fff;
        }

        .title {
          display: flex;
          padding: 7px 0;
          align-items: center;
          width: 70%;
          font-weight: 600;
          flex-wrap: wrap;
          overflow: hidden;
        }

        .description {
          width: 100%;
          font-size: 0.85em;
          font-weight: 400;
        }

        .edit-date-form {
          display: flex;
          align-items: center;
          justify-content: center;

          .month {
            padding: 5px;
            margin-left: 5px;
            margin-right: 5px;
            border: 1px solid #ccc;
            outline: none;
            font-size: 1em;
          }

          .years {
            padding: 5px;
            margin-right: 5px;
            border: 1px solid #ccc;
            outline: none;
            font-size: 1em;
          }

          .submit-btn {
            position: relative;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            background: #00b01c;
            border-radius: 50%;
            cursor: pointer;

            &.edit-date {
              position: static;
            }

            img {
              max-height: 11px;
            }
          }
        }

        .options {
          margin-right: 15px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .delete-btn,
          .download-btn,
          .save-btn {
            margin-left: 5px;
          }

          &.loader-dots {
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            .dot {
              width: 6px;
              height: 6px;
              margin: 3px;
              border-radius: 50%;
              background-color: #666;
              animation: dotBounce 0.8s infinite ease-in-out both;
            }

            .dot:nth-child(2) {
              animation-delay: 0.16s;
            }
            .dot:nth-child(3) {
              animation-delay: 0.32s;
            }
          }
        }
      }

      &__other-deals {
        position: relative;
        z-index: 10;
        margin: 15px 0 10px;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        padding: 12px 20px 2px;
        background: #f6f6f6;
        transition: 0.3s all ease;

        &.deals-collapsed {
          overflow: hidden;
        }
        &:not(.deals-collapsed) {
          padding: 12px 20px 10px;
        }
        .collapse-btn {
          transform: rotate(0deg);
          transition: 0.3s all ease;
        }
        .collapse-btn.deals-collapsed {
          transform: rotate(180deg);
        }
        .file-section .options {
          width: 15%;
        }
        &__header {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }

        .other-deals-date {
          margin: 22px 0 5px;
          &:first-child {
            margin: 13px 0 5px;
          }
        }
      }
    }

    @import './date-picker';
  }
}

@keyframes dotBounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
