.add-bank-account,
.add-folder-form,
.zip-confirm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;

    &__close {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 1;
    }

    &__inner {
        width: 20%;
        min-width: 250px;
        padding: 20px;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.3);
        z-index: 2;

        .head-section {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .title {
                display: flex;
                align-items: center;
                font-weight: bold;

                img {
                    max-height: 25px;
                    margin-right: 10px;
                }
            }

            .close-btn {
                position: relative;
                z-index: 10;
                width: 30px;
                height: 30px;
                background-color: #0072c4;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                img {
                    width: 14px;
                }

                &.white-icon{
                  max-height: 14px;
                }
            }
        }

        label {
            display: block;
            margin-bottom: 5px;
            font-size: .95em;
        }

        input[type="text"] {
            width: 100%;
            padding: 12px;
            margin-bottom: 15px;
            outline: none;
            border: 1px solid #e3e3e3;
            font-weight: 600;
            font-size: .95em;
        }

        .submit {
            display: inline-block;
            margin-right: 10px;
            width: 150px;
        }

        .cancel-btn {
            text-decoration: underline;
            font-weight: 600;
            cursor: pointer;
        }

        &-buttons-container{
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          .button-deny{

          }
        }
    }
    .zip-confirm__inner{
      width: 250px;
    }
}

.del-file-confirm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;

    &__close {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1;
    }

    &__inner {
        width: auto;
        padding: 25px 20px 20px;
        background-color: #fff;
        z-index: 2;

        .head-section {
            display: flex;
            align-items: center;
            font-weight: 600;
            margin-bottom: 10px;

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                width: 30px;
                height: 30px;
                border-radius: 50%;

                background-color: #ff0000;

                img {
                    max-height: 15px;
                }
            }
        }

        .message {
            margin-bottom: 15px;
        }

        .options {
            display: flex;
            align-items: center;

            .confirm-btn {
                width: 170px;
                margin-right: 15px;
                background-color: #ff0000;
            }

            .cancel-btn {
                text-decoration: underline;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
}

.loader-container {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  .loader {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-top: 10px solid #0072C4;
    border-right: 10px solid #0072C4;
    border-bottom: 10px solid #0072C4;
    border-left: 10px solid transparent;
    transform: translate(-50%, -50%) rotate(0deg);
    animation: rotate 1.3s infinite cubic-bezier(0.4, 0.85, 0.65, 0.25);
  }
}

.download-file-message{
  height: 160px;
  padding: 2rem;
  font-size: 1.1rem;
  text-align: center;
  background: #ffffffee;
}

.error-container{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 5;
  background: #1F2D37;
  &__error-message-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    top: 35%;
    left: 50%;
    color: #fff;
    transform: translate(-50%);
    img{
      width: 70px;
    }
    .text{
      font-weight: normal;
    }
    .header{
      margin-top: .5rem;
    }
    .more-info{
      font-weight: lighter;
      margin-top: 2rem;
    }
  }
}

.notification-clone{
  position: fixed;
  bottom: 0;
  width: 280px;
  right: -100%;
  transition: all 0.4s;
  &.active{
    right: 1rem;
  }
}
